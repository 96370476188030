<template>
	<div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>
        <div style="width:100%;height:500px;"></div>

        <div class="stat_layer">
            <div class="stat_box">
                <el-scrollbar wrap-class="scrollbar-wrapper" class="stat_scroll">
                    <div class="info_box">
                        <div class="area">浙江</div>
                        <div class="area">568分</div>
                        <div class="area">理科</div>
                        <div class="area batch">
                            <el-popover ref="PopoverBatch" placement="bottom" trigger="hover" width="150" @show="PopoverEnter" @hide="PopoverLeave" :close-delay="100">
                                <div slot="reference">本一批<i class="el-icon-arrow-down" v-if="PopoverShow"></i><i class="el-icon-arrow-up" v-if="!PopoverShow"></i></div>
                                <div class="batch_item_popo" @click.prevent.stop="BatchOptionCheck(1)">本一批</div>
                                <div class="batch_item_popo" @click.prevent.stop="BatchOptionCheck(2)">本二批</div>
                                <div class="batch_item_popo" @click.prevent.stop="BatchOptionCheck(3)">专科批</div>
                            </el-popover>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="stat">
                        <div class="item">
                            <div class="ty c">
                                <div class="num show_animate"><div class="shu num_animate">68</div></div>
                                <div class="border show_animate"></div>
                                <div class="t1 t_animate"><span class="bai">15%</span>冲一冲</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="ty w">
                                <div class="num show_animate"><div class="shu num_animate">168</div></div>
                                <div class="border show_animate"></div>
                                <div class="t1 t_animate"><span class="bai">23%</span>较稳当</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="ty b">
                                <div class="num show_animate"><div class="shu num_animate">256</div></div>
                                <div class="border show_animate"></div>
                                <div class="t1 t_animate"><span class="bai">62%</span>可保底</div>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="line"></div>
                    </div>
                    <div class="type">
                        <div class="head">共有<span class="n">526</span>所适合的院校</div>
                        <div class="list">
                            <div class="li">
                                <div class="s">48</div>
                                <div class="t">985</div>
                            </div>
                            <div class="li">
                                <div class="s">85</div>
                                <div class="t">211</div>
                            </div>
                            <div class="li">
                                <div class="s">168</div>
                                <div class="t">双一流</div>
                            </div>
                            <div class="li">
                                <div class="s">285</div>
                                <div class="t">省重点</div>
                            </div>
                            <div class="li">
                                <div class="s">365</div>
                                <div class="t">公办</div>
                            </div>
                            <div class="li">
                                <div class="s">451</div>
                                <div class="t">民办</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                    <div class="tips">
                        <div class="tt"><i class="iconfont">&#xe687;</i>温馨提示</div>
                        <div class="bz">1、2024年招生计划已更新</div>
                        <div class="bz">2、在正式填报时，以教育考试院公布的最新招生计划为准</div>
                        <div class="bz">3、若推荐院校招生计划数出现新增专业，会提示”新增/专业合并“且历三年录取数据展示默认为空</div>
                        <div class="bz">4、2012-2023年各院校录取数据，参考各省市出版的填报指南以及各本专科院校的官网历年录取数据</div>
                    </div>
                    <div class="clear" style="height:110px;"></div>
                    <div class="gbtn">
                        <div class="btn" @click.prevent="tolink()">立即填报</div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        
        <!-- 底部 -->
        <PFooter></PFooter>
	</div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
export default {
    components:{
        PHeader,
        PFooter
    },
	data() {
		return {
            BatchId:null,
            PopoverShow:false
		}
	},
	methods: {
        // 批次 选项选中
        BatchOptionCheck(val){
            this.BatchId = val;
            this.$refs.PopoverBatch.showPopper = false;//关闭批次弹出框
        },
        PopoverEnter(){
            this.PopoverShow=false;
        },
        PopoverLeave(){
            this.PopoverShow=true;
        },
        tolink(){
            this.$router.push("/recommend-index");
        }
	}
}
</script>

<style lang="less" scoped>
.pagebox{
    background-color: #fff;
    .stat_layer{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        background-color: rgba(0,0,0,0.5);
        .stat_box{
            width: 800px;
            height: calc(100% - 100px);
            background-color: #fff;
            border-radius: 5px;
            margin: auto;
            margin-top: 50px;
            overflow: hidden;
            .stat_scroll{
                width: 100%;
                height: 100%;
                .info_box{
                    color: #666;
                    font-size: 14px;
                    margin: 0px 30px;
                    line-height: 20px;
                    overflow: hidden;
                    padding-top: 25px;
                    .area{
                        float: left;
                        margin-right: 15px;
                        i{
                            font-size: 12px;
                            margin-left: 4px;
                            color: #999;
                        }
                    }
                    .batch:hover{
                        cursor: pointer;
                        color: rgba(var(--themecolor),1);
                        i{
                            color: rgba(var(--themecolor),1);
                        }
                    }
                }
                .stat{
                    margin-top: 40px;
                    height: 250px;
                    position: relative;
                    padding: 0 50px;
                    background: url(../../assets/images/statback.png) no-repeat center bottom / 100%;
                    .item{
                        width: calc(100% / 3);
                        position: relative;
                        float: left;
                        z-index: 3;
                        .ty{
                            margin: 0 auto;
                            width: 140px;
                            height: 140px;
                            background-color: #fff;
                            border-radius: 100px;
                            text-align: center;
                            position: relative;
                            .num{
                                border-radius: 100px;
                                width: 100px;
                                height: 100px;
                                .shu{
                                    color: #000;
                                    font-size: 32px;
                                    font-weight: bold;
                                    overflow: hidden;
                                    line-height: 100px;
                                }
                            }
                            .border{
                                width: 1px;
                                height: 30px;
                                background-color: #d1d0d0;
                                position: absolute;
                                left: 70px;
                                bottom: -30px;
                                z-index: 1;
                            }
                            .t1{
                                width: 120px;
                                height: 30px;
                                line-height: 30px;
                                border: 1px solid #d1d0d0;
                                position: absolute;
                                bottom: -62px;
                                left: 9px;
                                font-size: 15px;
                                text-align: center;
                                border-radius: 5px;
                                background-color: #fff;
                                .bai{
                                    color: #666;
                                    margin-right: 5px;
                                }
                            }
                            .num_animate{
                                animation: num_animate 2.2s ease 0s 1;
                                -webkit-animation: num_animate 2.2s ease 0s 1;
                                animation-fill-mode: forwards;
                                -webkit-animation-fill-mode: forwards;
                                @-webkit-keyframes num_animate {
                                    from {
                                        color: #ddd;
                                    }
                                    to {
                                        color: #000;
                                    }
                                }
                                @keyframes num_animate {
                                    from {
                                        color: #ddd;
                                    }
                                    to {
                                        color: #000;
                                    }
                                }
                            }
                            .show_animate{
                                animation: show_animate 1.8s ease 0s 1;
                                -webkit-animation: show_animate 1.8s ease 0s 1;
                                animation-fill-mode: forwards;
                                -webkit-animation-fill-mode: forwards;
                                @-webkit-keyframes show_animate {
                                    from {
                                        opacity: 0;
                                    }
                                    to {
                                        opacity: 1;
                                    }
                                }
                                @keyframes show_animate {
                                    from {
                                        opacity: 0;
                                    }
                                    to {
                                        opacity: 1;
                                    }
                                }
                            }
                            .t_animate{
                                animation: t_animate 1s ease 0s 1;
                                -webkit-animation: t_animate 1s ease 0s 1;
                                animation-fill-mode: forwards;
                                -webkit-animation-fill-mode: forwards;
                                @-webkit-keyframes t_animate {
                                    from {
                                        opacity: 0;
                                        bottom: -200px;
                                    }
                                    to {
                                        opacity: 1;
                                        bottom: -62px;
                                    }
                                }
                                @keyframes t_animate {
                                    from {
                                        opacity: 0;
                                        bottom: -200px;
                                    }
                                    to {
                                        opacity: 1;
                                        bottom: -62px;
                                    }
                                }
                            }
                        }
                        .ty.c .num{
                            border: 20px solid #d63434;
                        }
                        .ty.c .t1{
                            color: #d63434;
                        }
                        .ty.w .num{
                            border: 20px solid #ec8a20;
                        }
                        .ty.w .t1{
                            color: #ec8a20;
                        }
                        .ty.b .num{
                            border: 20px solid #1ebb72;
                        }
                        .ty.b .t1{
                            color: #1ebb72;
                        }
                    }
                    .line{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: 1;
                        width: 100%;
                        height: 40px;
                        background: linear-gradient(to top, #fff, transparent);
                    }
                }
                .type{
                    width: 100%;
                    background-color: #fff;
                    .head{
                        color: #666;
                        font-size: 18px;
                        text-align: center;
                        .n{
                            font-size: 26px;
                            margin: 0 14px;
                            color: rgba(var(--themecolor),1);
                        }
                    }
                    .list{
                        padding: 30px 30px 0;
                        background-color: #fff;
                        .li{
                            width: calc(100% / 3);
                            float: left;
                            height: 100px;
                            text-align: center;
                            .s{
                                font-size: 26px;
                                margin-top: 10px;
                                color: #444;
                                font-weight: bold;
                                letter-spacing: 2px;
                            }
                            .t{
                                font-size: 14px;
                                color: #888;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .tips{
                    margin: 0 30px;
                    background-color: #f8f7f7;
                    border: 1px dashed #ddd;
                    border-radius: 5px;
                    padding-bottom: 20px;
                    .tt{
                        color: #c95f08;
                        font-size: 16px;
                        padding: 20px 0 8px;
                        letter-spacing: 2px;
                        margin: 0 20px;
                        i{
                            font-size: 19px;
                            margin-right: 6px;
                        }
                    }
                    .bz{
                        color: #888;
                        font-size: 14px;
                        line-height: 18px;
                        margin: 10px 46px;
                    }
                }
                .gbtn{
                    width: 100%;
                    height: 80px;
                    background-color: #fff;
                    border-top: 1px solid #eee;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    .btn{
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        color: #fff;
                        margin: 15px;
                        border-radius: 5px;
                        letter-spacing: 2px;
                        cursor: pointer;
                        background-color: rgba(var(--themecolor),0.8);
                        transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                    }
                    .btn:hover{
                        background-color: rgba(var(--themecolor),1);
                    }
                }
            }
        }
    }
}
.batch_item_popo{
    color: #666;
    font-size: 15px;
    font-weight: bold;
    line-height: 36px;
    text-align: center;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    border-radius: 5px;
    cursor: pointer;
}
.batch_item_popo:hover{
    color: #444;
    background-color: rgba(var(--themecolor),0.15);
}

</style>
<style>
.stat_layer .stat_box .el-scrollbar__wrap{
    overflow-x: hidden;
}
</style>